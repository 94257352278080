<!--
 实名认证成功
-->

<template>
  <div>
    <div class="page-bg"></div>
    <div class="authsuccess-box">
      <van-image class="image" round :src="pic_avatar_login" />
      <div class="auth-name">已通过实名认证</div>
      <div class="auth-form">
        <van-form>
          <van-field
            v-model="mobile"
            type="tel"
            class="field"
            name="mobile"
            label="手机号"
            disabled
            :border="false"
          />

          <van-field
            v-model="id_name"
            name="id_name"
            class="field"
            label="真实姓名"
            :border="false"
            disabled
          />

          <van-field
            v-model="id_number"
            name="id_number"
            class="field"
            label="身份证号"
            :border="false"
            disabled
          />

          <van-button plain hairline type="default" class="back-btn" @click="goBack"
            >返回</van-button
          >
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import { getUserCollection } from '@/service/user';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { nftUtils } from '@/utils';
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      mobile: '',
      id_name: '',
      id_number: ''
    });

    onMounted(async () => {
      if (store.state.loginInfo && store.state.loginInfo.mobile) {
        state.mobile = nftUtils.getNoFullMobile(store.state.loginInfo.mobile);
      }
      const { data, status } = await getUserCollection();
      if (data && status === 0) {
        state.mobile = nftUtils.getNoFullMobile(data.mobile);
        state.id_name = data.id_name;
        state.id_number = data.id_number;
      }
    });

    const goBack = () => {
      nftUtils.setcnzzRecordByBtn('认证成功页点击返回');
      if (window.history.length <= 1) {
        router.push({ path: '/', replace: true });
      } else {
        router.go(-1);
      }
    };

    return {
      ...toRefs(state),
      goBack,
      pic_avatar_login: require('../imgs/comm/pic_avatar_login.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.authsuccess-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  padding-top: 40px;
  position: relative;
  width: 100%;

  .image {
    width: 88px;
    height: 88px;
  }
  .auth-name {
    font-size: 16px;
    margin-top: 16px;
  }

  .auth-form {
    margin-top: 20px;
  }
  .field {
    margin-top: 12px;
    width: 335px;
    height: 44px;
    background-color: $primaryc;
    left: 50%;
    transform: translateX(-50%);
    --van-field-label-color: #fff;
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
    --van-field-input-disabled-text-color: #fff;
    --van-field-disabled-text-color: #fff;
  }
  .back-btn {
    margin-top: 32px;
    font-size: 16px;
    width: 335px;
    height: 44px;
    --van-button-plain-background-color: $bc;
    --van-button-default-border-color: $fc;
    --van-button-border-width: 1px;
    --van-button-default-color: $fc;
    --van-button-border-radius: 4px;
  }
}
</style>
